import React from 'react';
import '../style/FifthScreen.css'; 
import fiki from '../assets/fiki.png';
import kaca from '../assets/kaca.png';
import aca from '../assets/aca.png';
import aleksandra from '../assets/aleksandra.png';
import { FaInstagram, FaFacebookF } from "react-icons/fa6";

const ThirdScreen = () => {
  return (
    <div className='screen'>
      <div className='md:py-20 border-t border-gray-800'>
        <div className="max-w-3xl mx-auto pb-8 md:pb-20">
            <h1 className="mb-4 text-gray-100 text-center font-naslov">FiS Media - ko smo mi?</h1>
            <p className="text-xl text-gray-300 text-center font-tekst">Ukoliko zelite da nas upoznate bla bla bla</p>
        </div>
        <div className='mt-5 grid md:grid-cols-4'>
                <div className='mx-2'>
                  <img data-aos="fade-up" src={fiki} alt='' className='w-72 h-72'></img>
                  <h4 className='mt-4 text-gray-100 font-tekst3' data-aos="fade-left">Filip Stevanovic</h4>
                  <div data-aos="fade-left" className='bedzevi flex mt-3'>
                    <p className='bedz'>Fotograf</p>
                    <p className='bedz'>Snimatelj</p>
                    <p className='bedz'>Muzicar</p>
                  </div>
                  <p data-aos="fade-down" className='text-gray-300 text-sm'>Vlasnik i suvlasnik firme i poreduzeca asidasidj</p>
                  <div className='ikonice flex'>
                    <a href="https://www.instagram.com/filip_fis_/" target='_blank' rel="noreferrer" data-aos="fade-up" className='ikonica'><FaInstagram  /></a>
                    <a href="https://www.facebook.com/filip.stevanovic.54" target='_blank' rel="noreferrer" data-aos="fade-up" className='ikonica'><FaFacebookF /></a>
                  </div>
                </div>
                <div className='mx-2'>
                  <img data-aos="fade-up" src={kaca} alt='' className='w-72 h-80'></img>
                  <h4 className='mt-4 text-gray-100 font-tekst3' data-aos="fade-left" data-aos-delay="200">Katarina Jevtic</h4>
                  <div data-aos="fade-right" data-aos-delay="200" className='bedzevi flex mt-3'>
                    <p className='bedz'>Fotograf</p>
                    <p className='bedz'>Snimatelj</p>
                  </div>
                  <p data-aos="fade-down" data-aos-delay="200" className='text-gray-300 text-sm'>Vlasnik i suvlkasnik i kod nje je i noz i kolac</p>
                  <div className='ikonice flex'>
                    <a href="https://www.instagram.com/kata_foto_/" target='_blank' rel="noreferrer" data-aos="fade-up" data-aos-delay="200" className='ikonica'><FaInstagram  /></a>
                    <a href="https://www.facebook.com/kjevtic94" target='_blank' rel="noreferrer" data-aos="fade-up" data-aos-delay="200" className='ikonica'><FaFacebookF /></a>
                  </div>
                </div>
                <div className='mx-2'>
                  <img data-aos="fade-up" data-aos-delay="300" src={aca} alt='' className='w-72 h-72'></img>
                  <h4 className='mt-4 text-gray-100 font-tekst3' data-aos="fade-left" data-aos-delay="300">Aleksandar Simic</h4>
                  <div data-aos="fade-left" data-aos-delay="300" className='bedzevi flex mt-3'>
                    <p className='bedz'>Fotograf</p>
                    <p className='bedz'>Snimatelj</p>
                    <p className='bedz'>Dizajner</p>
                  </div>
                  <p data-aos="fade-down" data-aos-delay="300" className='text-gray-300 text-sm'>Kolega koji slika, snima i sve radnje radi, takodje kreator ovog divnog sajta</p>
                  <div className='ikonice flex'>
                    <a href="https://www.instagram.com/simic_0812/" target='_blank' rel="noreferrer" data-aos="fade-up" data-aos-delay="300" className='ikonica'><FaInstagram  /></a>
                    <a href="https://www.facebook.com/simke.brat/" target='_blank' rel="noreferrer" data-aos="fade-up" data-aos-delay="300" className='ikonica'><FaFacebookF /></a>
                  </div>
                </div>
                <div className='mx-2'>
                  <img data-aos="fade-up" data-aos-delay="400" src={aleksandra} alt='' className='w-72 h-80'></img>
                  <h4 className='mt-4 text-gray-100 font-tekst3' data-aos="fade-left" data-aos-delay="400">Aleksandra Jevtic</h4>
                  <div data-aos="fade-right" data-aos-delay="400" className='bedzevi flex mt-3'>
                    <p className='bedz'>Fotograf</p>
                    <p className='bedz'>Blagajnik</p>
                  </div>
                  <p data-aos="fade-down" data-aos-delay="400" className='text-gray-300 text-sm'>Draga koleginica koja po potrebi slika i prodaje vam nase lepe slike</p>
                  <div className='ikonice flex'>
                    <a href="https://www.instagram.com/aleksandra_jeee/" target='_blank' rel="noreferrer" data-aos="fade-up" data-aos-delay="400" className='ikonica'><FaInstagram  /></a>
                    <a href="https://www.facebook.com/aleksandra.jevtic.96" target='_blank' rel="noreferrer" data-aos="fade-up" data-aos-delay="400" className='ikonica'><FaFacebookF /></a>
                  </div>
                </div>
            </div>
      </div>
    </div>
  )
}

export default ThirdScreen
