import React from 'react';
import '../style/Footer.css';
import {BsFacebook,BsInstagram} from 'react-icons/bs';

const Footer = () => {
  return (
    <div className='footer'>
        <div className='footer-social'>
            <span className='icon text-gray-100'><BsFacebook className='social-icon'/></span>&nbsp;&nbsp;
            <span className='icon text-gray-100'><BsInstagram className='social-icon'/></span>
        </div>
        <p className='text-gray-100'>© FiS Media - 2024 Copyright | Site made by <a href="https://www.instagram.com/simic_0812/" target='_blank' rel="noreferrer">SimkE</a></p>
    </div>
  )
}

export default Footer