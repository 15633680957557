import React from 'react';
import '../style/SecondScreen.css'; 
import { MdAddAPhoto, MdVideoCameraBack, MdOutlineMusicNote } from "react-icons/md";

const SecondScreen = () => {
  return (
    <div className='screen'>
        <div className='md:py-20 border-t border-gray-800'>
            <h1 data-aos="fade-down" className='naslov text-center text-gray-100 font-naslov4'>Delatnosti kojima se bavimo</h1>
            <div className='mt-10 grid md:grid-cols-3 text-center'>
                <div className='px-3'>
                    <p data-aos="fade-down" data-aos-delay="100"><MdAddAPhoto className='sss-icon text-gray-100' /></p>
                    <h1 className='mt-4 text-gray-100 font-naslov2' data-aos="fade-left">Slikanje</h1>
                    <p className='mt-4 text-gray-300 font-tekst' data-aos="fade-right">Photoshooting u nasem studiu kao i na bilo kojoj drugoj lokaciji. Zakazivanje slikanja svih vrsta slavlja.</p>
                </div>
                <div className='px-3'>
                    <p data-aos="fade-down" data-aos-delay="200"><MdVideoCameraBack className='sss-icon text-gray-100' /></p>
                    <h1 className='mt-4 text-gray-100 font-naslov2' data-aos="fade-left" data-aos-delay="200">Photoshooting</h1>
                    <p className='mt-4 text-gray-300 font-tekst' data-aos="fade-right" data-aos-delay="200">Snimanje slavlja, snimanje covera ili bilo koja druga vrsta snimanja kao i video edit.</p>
                </div>
                <div className='px-3'>
                    <p data-aos="fade-down" data-aos-delay="300"><MdOutlineMusicNote className='sss-icon text-gray-100' /></p>
                    <h1 className='mt-4 text-gray-100 font-naslov2' data-aos="fade-left" data-aos-delay="300">Snimanje</h1>
                    <p className='mt-4 text-gray-300 font-tekst' data-aos="fade-right" data-aos-delay="300">Sviranje i pevanje kao i izdavanje ozvucenja za sve vrste slavlja</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SecondScreen
