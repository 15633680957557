import React from 'react';
import '../style/FirstScreen.css';
import logo from '../assets/logo.png';
import {AiOutlineArrowRight} from 'react-icons/ai';

const FirstScreen = () => {
  return (
    <div className='naslovna'>
          <div>
              <img src={logo} data-aos="fade-up" data-aos-delay="400" alt='' className='naslovna-logo'></img>
              <h1 data-aos="fade-left" data-aos-delay="500" className='font-naslov3 mt-16 text-gray-100'>Usluge za sve vrste slavlja</h1>
              <p data-aos="fade-right" data-aos-delay="600" className='font-tekst2 mt-8 md:px-12 text-gray-300'>Vrsimo usluge slikanja, snimanja, sviranja, takodje vrsimo i uslugu izdavanja ozvucenja!</p>
              <button data-aos="fade-down" data-aos-delay="700" className='btn-home text-gray-100'><p className='d-flex my-auto'>Buy Now&nbsp;&nbsp;</p><AiOutlineArrowRight  className='d-flex my-auto'/></button>
          </div>
      </div>
  )
}

export default FirstScreen
