import React from 'react';
import {
    MDBCol,
    MDBRow,
  } from 'mdb-react-ui-kit';
import '../style/FourScreen.css';
import gal1 from '../assets/gal1.png';
import gal2 from '../assets/gal2.png';
import gal3 from '../assets/gal3.png';
import gal4 from '../assets/gal4.png';
import gal5 from '../assets/gal5.png';
import gal6 from '../assets/gal6.png';
import gal1h from '../assets/gal1h.png';
import gal2h from '../assets/gal2h.png';
import gal3h from '../assets/gal3h.png';
import gal4h from '../assets/gal4h.png';
import gal5h from '../assets/gal5h.png';
import gal6h from '../assets/gal6h.png';



const FifthScreen = () => { 
  return (
    <MDBRow className='screen proba'>
        <div className="md:py-20 border-t border-gray-800 max-w-3xl mx-auto pb-8 md:pb-20">
            <h1 className="mb-4 text-gray-100 text-center font-naslov">Neki od nasih radova</h1>
        </div>
        <MDBCol lg={4} md={12} className='mb-4 mb-lg-0'>
          <div className='minigal'>
            <img
              src={gal1}
              className='w-100 rounded'
              alt='Boat on Calm Water'
              data-aos="fade-left"
              data-aos-delay="200"
            />
          </div>
          <div className='minigal'>
            <img
              src={gal1h}
              className='w-100 shadow-1-strong rounded'
              alt='Wintry Mountain Landscape'
              data-aos="fade-left"
              data-aos-delay="300"
            />
          </div>
        </MDBCol>

        <MDBCol lg={4} className='mb-4 mb-lg-0'>
          <div className='minigal'>
            <img
              src={gal2h}
              className='w-100 shadow-1-strong rounded'
              alt='Boat on Calm Water'
              data-aos="fade-left"
              data-aos-delay="200"
            />
          </div>
          <div className='minigal'>
            <img
              src={gal2}
              className='w-100 shadow-1-strong rounded'
              alt='Wintry Mountain Landscape'
              data-aos="fade-left"
              data-aos-delay="300"
            />
          </div>
        </MDBCol>

        <MDBCol lg={4} className='minigal mb-4 mb-lg-0'>
          <div className='minigal'>
            <img
              src={gal3}
              className='w-100 shadow-1-strong rounded'
              alt='Boat on Calm Water'
              data-aos="fade-left"
              data-aos-delay="200"
            />
          </div>
          <div className='minigal'>
            <img
              src={gal3h}
              className='w-100 shadow-1-strong rounded'
              alt='Wintry Mountain Landscape'
              data-aos="fade-left"
              data-aos-delay="300"
            />
          </div>
        </MDBCol>
        <MDBCol lg={4} md={12} className='mb-4 mb-lg-0'>
          <div className='minigal'>
            <img
              src={gal4}
              className='w-100 shadow-1-strong rounded'
              alt='Boat on Calm Water'
              data-aos="fade-left"
              data-aos-delay="200"
            />
          </div>
          <div className='minigal'>
            <img
              src={gal4h}
              className='w-100 shadow-1-strong rounded'
              alt='Wintry Mountain Landscape'
              data-aos="fade-left"
              data-aos-delay="300"
            />
          </div>
        </MDBCol>

        <MDBCol lg={4} className='mb-4 mb-lg-0'>
          <div className='minigal'>
            <img
              src={gal5h}
              className='w-100 shadow-1-strong rounded'
              alt='Boat on Calm Water'
              data-aos="fade-left"
              data-aos-delay="200"
            />
          </div>
          <div className='minigal'>
            <img
              src={gal5}
              className='w-100 shadow-1-strong rounded'
              alt='Wintry Mountain Landscape'
              data-aos="fade-left"
              data-aos-delay="300"
            />
          </div>
        </MDBCol>

        <MDBCol lg={4} className='mb-4 mb-lg-0'>
          <div className='minigal'>
            <img
              src={gal6}
              className='w-100 shadow-1-strong rounded'
              alt='Boat on Calm Water'
              data-aos="fade-left"
              data-aos-delay="200"
            />
          </div>
          <div className='minigal'>
            <img
              src={gal6h}
              className='w-100 shadow-1-strong rounded'
              alt='Wintry Mountain Landscape'
              data-aos="fade-left"
              data-aos-delay="300"
            />
          </div>
        </MDBCol>
    </MDBRow>
  )
}

export default FifthScreen
