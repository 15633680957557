import React from 'react';

const Mapa = () => {
  return (
    <div>
      <iframe className="gmap_iframe h-80 mt-5" style={{width:"100%", height:"25vh", frameborder:"4", scrolling:"no", marginheight:"0", marginwidth:"0"}} src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2881.4178749460175!2d21.42840507649205!3d43.76418384524511!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDPCsDQ1JzUxLjEiTiAyMcKwMjUnNTEuNSJF!5e0!3m2!1ssr!2srs!4v1714055722740!5m2!1ssr!2srs"/>
    </div>
  )
}

export default Mapa
