import { React, useEffect } from 'react';
import AOS from 'aos'
import 'aos/dist/aos.css'
import FirstScreen from '../components/FirstScreen';
import SecondScreen from '../components/SecondScreen';
import ThirdScreen from '../components/ThirdScreen';
import FourScreen from '../components/FourScreen';
import FifthScreen from '../components/FifthScreen';
import Mapa from '../components/Mapa';

const Home = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 600,
      easing: 'ease-out-sine',
    })
  })
  return (
    <>
      <FirstScreen />
      <SecondScreen />
      <ThirdScreen />
      <FourScreen />
      <FifthScreen />
      <Mapa />
    </>
  )
}

export default Home