import React from 'react';
import slika1 from '../assets/slika1.png';
import slika2 from '../assets/slika2.png';
import slika3 from '../assets/slika3.png';

const FourScreen = () => {
  return (
    <div className='screen'>
      <div className='md:py-20 border-t border-gray-800'>
        <div className='grid md:grid-cols-2 gap-5'>
            <img src={slika1} alt='' data-aos="fade-right" className='sm-odvajanje'></img>
            <div>
                <h3 data-aos="fade-left" className='text-gray-100 mt-3 font-naslov'>Slikanje vasih slavlja</h3>
                <p data-aos="fade-left" className='text-gray-300 mt-5 font-tekst'>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                    when an unknown printer took a galley of type and scrambled it to make a type 
                    specimen book. It has survived not only five centuries, but also the leap into 
                    electronic typesetting, remaining essentially unchanged. It was popularised 
                    in the 1960s with the release of Letraset sheets containing Lorem Ipsum 
                    passages, and more recently with desktop publishing software like Aldus 
                    PageMaker including versions of Lorem Ipsum.
                </p>
            </div>
        </div>
        <div className='komp odvajanje grid md:grid-cols-2 gap-5'>
            <div>
                <h3 data-aos="fade-right" className='text-gray-100 mt-3 font-naslov'>Photoshooting</h3>
                <p data-aos="fade-right" className='text-gray-300 mt-5 font-tekst'>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                    when an unknown printer took a galley of type and scrambled it to make a type 
                    specimen book. It has survived not only five centuries, but also the leap into 
                    electronic typesetting, remaining essentially unchanged. It was popularised 
                    in the 1960s with the release of Letraset sheets containing Lorem Ipsum 
                    passages, and more recently with desktop publishing software like Aldus 
                    PageMaker including versions of Lorem Ipsum.
                </p>
            </div>
            <img data-aos="fade-left" src={slika2} alt=''></img>
        </div>
        <div className='mobilni odvajanje grid md:grid-cols-2 gap-5'>
            <img data-aos="fade-left" src={slika2} alt=''></img>
            <div>
                <h3 data-aos="fade-right" className='text-gray-100 mt-3 font-naslov'>Photoshooting</h3>
                <p data-aos="fade-right" className='text-gray-300 mt-5 font-tekst'>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                    when an unknown printer took a galley of type and scrambled it to make a type 
                    specimen book. It has survived not only five centuries, but also the leap into 
                    electronic typesetting, remaining essentially unchanged. It was popularised 
                    in the 1960s with the release of Letraset sheets containing Lorem Ipsum 
                    passages, and more recently with desktop publishing software like Aldus 
                    PageMaker including versions of Lorem Ipsum.
                </p>
            </div>
        </div>
        <div className='odvajanje grid md:grid-cols-2 gap-5'>
            <img data-aos="fade-right" src={slika3} alt=''></img>
            <div>
                <h3 data-aos="fade-left" className='text-gray-100 mt-3 font-naslov'>Zabelezite posebne trenutke</h3>
                <p data-aos="fade-left" className='text-gray-300 mt-5 font-tekst'>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                    when an unknown printer took a galley of type and scrambled it to make a type 
                    specimen book. It has survived not only five centuries, but also the leap into 
                    electronic typesetting, remaining essentially unchanged. It was popularised 
                    in the 1960s with the release of Letraset sheets containing Lorem Ipsum 
                    passages, and more recently with desktop publishing software like Aldus 
                    PageMaker including versions of Lorem Ipsum.
                </p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default FourScreen
